
import { Component, Vue } from 'vue-property-decorator'
import { PageType } from '../../types/mechanics'
import { FileInfo } from '@/types/common'

@Component({
  name: 'mechanicsDetail',
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class extends Vue {
  private info: PageType = {
    manageId: '',
    machineName: '',
    machineAlias: '',
    useMethod: '',
    readingCounts: '',
    resourceList: []
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.mechanics.selectMachineByManageId, {
      manageId: this.$route.params.id
    }).then(res => {
      this.info = res
    })
  }
}
